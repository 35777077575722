import React from 'react'
import Title from 'components/Title'
import Container from 'components/Container'
import ExternalLink from 'components/ExternalLink'
import Footer from "components/Footer"
import Header from "components/Header"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useSiteMetadata } from 'hooks/useSiteMetadata'

export default () => {

    const { t } = useTranslation()

    const { title } = useSiteMetadata()

    return (
        <>
        <Header/>
        <main className='main-content' style={{ textAlign: 'center' }}>
            <Title dark title={t('Contact us')} subtitle={ t('We would love to hear from you') } />

            {/* <Container>
                <iframe 
                    src="https://cdn.forms-content.sg-form.com/8e4ae6f6-2a8a-11eb-a1d1-4e330953a988" 
                    frameBorder='0'
                    width='100%'
                    height="544"
                    title='contact-us'
                />
            </Container> */}
            
            <Container>

                <h1>{ t(`Support`) }</h1>
                <p>
                    { t(`Need help getting {{ siteTitle }} set up?`, { siteTitle: title }) } <ExternalLink href='https://support.napaconnect.ca'>{ t(`Visit our support site`) }</ExternalLink>
                </p>
                

            </Container>

            <Container>

                <h1>{ t(`Talk to an expert`) }</h1>
                <p>{ t(`We can find the right solution for you, explain pricing options, and connect you with helpful resources.`) }</p>
                <a href='mailto:hello@napaconnect.ca'>hello@napaconnect.ca</a>

            </Container>

        </main>
        <Footer/>
        </>
    )
}